import React, { useEffect, useState } from 'react';
import './FieldRadio.scss';
import { Box, Typography, FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ErrorsField from '../../ErrorsField/ErrorsField';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useUser } from '../../../services/userContext';

const FieldRadio = (props) => {
  const {field,onChange} = props
  const [selectedValue,setSelectedValue] = useState(field.value)
  const [isInit,setIsInit] = useState(false)

  const {isDebug} = useUser()
 
  const handleChange = (event) =>{
    setSelectedValue(event.target.value)
  }


  useEffect(()=>{
    if(isInit){
    onChange(field.name,selectedValue,'radio')
    }else{
      setIsInit(true)
    }
    // eslint-disable-next-line
  },[selectedValue])


  return (
    <Box>
      <Box>
        {isDebug && field.name+' : '+field.value +' - '+(typeof field.value) + ' - '+field.type+ ' - '+field.source}
      </Box>
      <Box sx={{display:'flex', gap:3, alignItems:'center'}}>
        <Box>
          <Typography variant="inputLabel">{field.label} {field.isRequired && '*'}</Typography>
        </Box>
        <Box>
          <FormControl>
            <RadioGroup row>
              {field.options.map((option,key)=>(<FormControlLabel 
              key={key}
              onChange={handleChange} 
              value={option.value} 
              // eslint-disable-next-line
              checked={selectedValue == option.value}
              control={<BpRadio/>} 
              label={<Typography variant="inputLabel">{option.label}</Typography>} />))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {field.help && <Typography>{field.help}</Typography>}
      <ErrorsField field={field} />
    </Box>
  )
};

function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon><CheckIcon sx={{width:'15px',height:'15px'}} /></BpCheckedIcon>}
      icon={<BpIcon />}
      {...props}
    />
  );
}


const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '4px',
  width: 16,
  height: 16,
  borderSize:'1px',
  backgroundColor: 'white',
  border: '1px solid #C2C2C2'
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FF2822',
  border: '1px solid #FF2822',
  color:'white',
  display:'flex',
  alignItems:'center',
  justifyContent:'center'
 
});




export default FieldRadio;
