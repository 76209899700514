import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedinLogo from '../../../src/assets/img/linkedin.svg'

const Footer = () => {
  
  const year = new Date().getFullYear()
  
  
  return(
  <Box sx={{backgroundColor:'#424242',
  color:'white',
  fontSize:'12px',
   width:'100%',
   minHeight:'100px',
   paddingX:4,
   paddingY:4,
   boxSizing:'border-box',
   display:'flex',
   flexDirection:['column','column','row'],
   gap:[2,2,0],
   alignItems:'center',
   justifyContent:'space-between',
   fontFamily:'Red Hat Display',
   }}>
    <Box>
      ©Les Mousquetaires {year}
    </Box>
    <Box sx={{display:'flex',flexDirection:['column','column','row'],justifyContent:'center',textAlign:'center',gap:1}}>
      <Box>
        <Link style={{color:'white'}} to="/mentions-legales">Mentions légales</Link>
      </Box>
      <Box sx={{display:['none','none','block']}}>|</Box>
      <Box>
        <Link style={{color:'white'}} to="/politique-donnees">Politique Données Personnelles Candidat</Link>
      </Box>
    </Box>
    <Box sx={{display:'flex',alignItems:'center'}}>
      Nous suivre : 
      <a style={{color:'white',marginRight:'10px',marginLeft:'10px'}} target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/les-mousquetaires"><img src={LinkedinLogo} sx={{width:'20px',height:'20px'}}  alt="logo linkedin"/></a>
      <a style={{color:'white'}} target="_blank" rel="noreferrer"  href="https://fr-fr.facebook.com/DevenezMousquetaires/"><FacebookRoundedIcon sx={{width:'20px',height:'20px'}}  /></a>
      
    </Box>
  </Box>
)};

export default Footer;
