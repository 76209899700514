import { Link } from '@mui/material';
import React from 'react';
import './MailUDM.scss';


const MailUDM = () => {return (<>
    <Link sx={{color:'#FF2822',textDecoration:'none',fontWeight:'700'}} href="mailto:udm_recrutement@mousquetaires.com">udm_recrutement@mousquetaires.com</Link>
    <br />
</>)};
export default MailUDM;
  