import React from "react";
import { A, Box, Email, Item, Image, Span } from "react-html-email";

const MailContainer = (props) => {

    const year = new Date().getFullYear() 

    const emailHeadCSS = `
        body {
            background-color: white;
            font-family:Red Hat Display,sans-serif;
        }
        `.trim();

    return (
        <Email headCSS={emailHeadCSS}>
            <Box align="center" style={{width:'100%',backgroundColor:'#FAFAFA',fontFamily:'Red Hat Display, sans-serif',paddingLeft:'20px',paddingRight:'20px',}}>
                <Item>
                    <Box align="center" style={{width:'500px',maxWidth:'90vw'}}>
                        <Box align="center" style={{marginTop:'20px',marginBottom:'20px'}}>
                            <Item>
                                <Image width="200" src="https://changerdevie.mousquetaires.com/app/uploads/2022/11/logonew.png" alt="logo udm" />
                            </Item>
                        </Box>

                        <Box style={{width:'100%'}}>
                            <Item style={{with:'100%'}} >
                                <Span style={{with:'100%',height:'1px',backgroundColor:'#EDEDED',display:'block'}}></Span>
                            </Item>
                        </Box>


                        {props.children}

                        <Box align="center" style={{paddingTop:'35px',paddingBottom:'35px',textAlign:'center'}}>
                            <Item>
                                Bien cordialement,<br />
                                L'équipe recrutement de tréville
                            </Item>
                        </Box>

                        <Box style={{width:'100%'}}>
                            <Item style={{with:'100%'}} >
                                <Span style={{with:'100%',height:'1px',backgroundColor:'#EDEDED',display:'block'}}></Span>
                            </Item>
                        </Box>


                    

                        <Box align="center" style={{paddingTop:'35px',paddingBottom:'35px'}} >
                            <Item align="center">
                                <A href="https://apps.apple.com/fr/app/devenez-mousquetaires/id1455985486">
                                    <Image width="128" height="42" style={{marginRight:'10px',display:'inline-block'}} src="https://changerdevie.mousquetaires.com/app/uploads/2022/02/App-Store-badge-1.png" alt="badge ios"/>
                                </A>
                                <A href="https://play.google.com/store/apps/details?id=com.mousquetaires.appdevenez&hl=fr">
                                    <Image width="128" height="42" style={{display:'inline-block'}} src="https://changerdevie.mousquetaires.com/app/uploads/2022/02/Google-Play-badge-1.png" alt="badge google play" />
                                </A>
                            </Item>
                        </Box>

                        <Box style={{width:'100%'}}>
                            <Item style={{with:'100%'}} >
                                <Span style={{with:'100%',height:'1px',backgroundColor:'#EDEDED',display:'block'}}></Span>
                            </Item>
                        </Box>

                        <Box align="center" style={{paddingTop:"35px",paddingBottom:'18px'}}>
                            <Item>
                                <Span>Nous suivre :</Span>
                                <Span>
                                    <A href="https://www.facebook.com/DevenezMousquetaires">
                                        <Image style={{display:'inline',marginLeft:'5px'}} width="18" height="18"  src="https://changerdevie.mousquetaires.com/app/uploads/2022/11/facebook.png" alt="picto-facebook"/>
                                    </A>
                                    </Span>
                                <Span>
                                    <A href="https://www.linkedin.com/company/les-mousquetaires/">
                                        <Image style={{display:'inline',marginLeft:'5px'}} width="18" height="18" src="https://changerdevie.mousquetaires.com/app/uploads/2022/11/linkedin.png" alt="picto-linkedin"/>
                                    </A>
                                </Span>
                            </Item>
                        </Box>

                        <Box align="center">
                            <Item>
                                <Span>©Les Mousquetaires {year}</Span>
                            </Item>
                        </Box>

                        <Box align="center" style={{marginTop:"10px",marginBottom:'10px'}}>
                            <Item>
                                <Span style={{textDecoration:"underline"}}>
                                    <A href="[lien-mentions-legales]" style={{color:'black'}}>Mentions légales</A>
                                </Span>
                                <Span style={{marginLeft:"5px",marginRight:"5px"}}>|</Span>
                                <Span style={{textDecoration:"underline"}}>
                                    <A href="[lien-politique-groupe]" style={{color:'black'}}>Politique groupe</A> 
                                </Span>
                                <Span style={{marginLeft:"5px",marginRight:"5px"}}>|</Span>
                                <Span style={{textDecoration:"underline"}}>
                                    <A href="[lien-credits]" style={{color:'black'}}>Crédits</A> 
                                </Span>
                            </Item>
                        </Box>
                        
                        <Box align="center" style={{marginTop:"10px",marginBottom:'10px',textAlign:'center'}}>
                            <Item>
                                <Span style={{textAlign:'center',fontSize:'10px'}}>
                                    Aliquam hendrerit sed ut amet tristique vitae, id. Mi diam scelerisque ultricies sit. Sociis viverra malesuada sit malesuada congue nisl neque, turpis. Dictum et, purus phasellus mauris sagittis id tempus. In id dui facilisis venenatis mauris imperdiet. At venenatis neque consequat vitae, habitasse quis faucibus.
        Aliquet sed pulvinar volutpat, nunc turpis quis dolor aliquam posuere. Blandit ullamcorper semper nam rhoncus augue amet nunc. Porta mauris urna, aliquet maecenas tortor. Viverra id dui arcu mattis accumsan fermentum egestas blandit diam. Commodo nisi, sit tempor fermentum, rhoncus massa. Massa quisque auctor mus placerat ipsum netus magna. Ultricies leo auctor hendrerit turpis egestas senectus sed habitant. Velit nulla pharetra mus.
                                </Span>
                            </Item>
                        </Box>


                        
                    </Box>
                </Item>
            </Box>
        </Email>
    )
}

export default MailContainer;

export const Button = (props) => {

    return (
        <Box align="center" style={{backgroundColor:'#FF2822',padding:'14px 20px',color:'white',fontWeight:500,fontSize:'16px',borderRadius:'2px'}}>
            <Item>
                <A href={props.href} style={{textDecoration:'none',color:'white'}}>
                    {props.children}
                </A>
            </Item>
        </Box>
    )
}
