import React from "react";
import { A, Box, Item, Span } from "react-html-email";
import MailContainer, { Button } from "./MailContainer";

const JourneeInformation = (props) => {

    return (
        <MailContainer>
            <Box>
                <Item>
                    <Box style={{backgroundColor:'white', width:'100%',paddingTop:'50px',paddingBottom:'50px',paddingLeft:'40px',paddingRight:'40px',borderTopLeftRadius:'12px',borderTopRightRadius:'12px',marginTop:'35px',boxShadow:'0px 6px 16px 0px #00000014',textAlign:'center',fontWeight:'400',fontSize:'18px'}}>
                        <Item>
                           
                            <Box align="center" style={{marginBottom:'20px'}}>
                                <Item>
                                    <Span style={{color:'#FF2822',fontWeight:'600',fontSize:'18px'}}>
                                    Vous venez de participer à la Journée d’information.
                                    </Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{marginBottom:'20px'}}>
                                <Item>
                                    <Span style={{fontWeight:600}}>Votre dossier financier n’est pas encore validé </Span>
                                    <Span> nous vous remercions de bien vouloir le finaliser afin que nos équipes puissent contrôler les éléments en amont de votre entretien.</Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{marginBottom:'20px'}}>
                                <Item>
                                    <Span> Afin d’ajouter les éléments manquants à votre dossier, merci de cliquer sur le lien ci-dessous :   </Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{marginTop:'20px',marginBottom:'0px'}}>
                                <Item>
                                    <Button href={'[lien]'}>Accéder a mon dossier</Button>
                                </Item>
                            </Box>

                        </Item>
                    </Box>
                    <Box style={{width:'100%',backgroundColor:'#FF2822',borderBottomLeftRadius:'12px',borderBottomRightRadius:'12px',padding:'20px 30px',color:'white',marginBottom:'35px'}}>
                        <Item>
                            <Box style={{marginBottom:'0px',color:'white'}}>
                                <Item>
                                    <Span style={{color:'white',fontWeight:600}}>
                                    Pour toute question ou demande, vous pouvez joindre notre service à l’adresse : 
                                    </Span>
                                </Item>
                            </Box>
                            <Box style={{marginTop:'10px',marginBottom:'0px', color:'white'}}>
                                <Item>
                                    <A href='mailto:udm_recrutement@mousquetaires.com' style={{color:'white'}}>
                                        <Span style={{color:'white'}}>
                                            udm_recrutement@mousquetaires.com
                                        </Span>
                                    </A>
                                </Item>
                            </Box>
                        </Item>
                    </Box>
                </Item>
            </Box>
        </MailContainer>
    )
}

export default JourneeInformation;