import React, { useState, useEffect } from 'react';
import './Connexion.scss';
import { Box} from '@mui/system';
import { Grid, IconButton, Typography, useTheme,  } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ConnexionLayout from '../../../components/ConnexionLayout/ConnexionLayout';
import { useUser } from '../../../services/userContext';
import TextFieldCustom from '../../../components/TextFieldCustom/TextFieldCustom';
import useKeyPress from '../../../services/useKeyPress';
import ErrorsField from '../../../components/ErrorsField/ErrorsField';




const Connexion = (props) => {

  const navigate = useNavigate();
  const {connectUser} = useUser()
  const [searchParams] = useSearchParams();


  const [showPassword,setShowPassword] = useState(false)
  const theme = useTheme();
  const handleShowPassword = () => {
      setShowPassword(showPassword ? false : true)
  }

  const [mail,setMail] = useState(searchParams.get('email') || '')
  const [password,setPassword] = useState('')
  const [loading,setLoading] = useState(false)
  const [errorsMessage,setErrorsMessage] = useState('')

  const getTokenConnexion = async () => {
    setLoading(true)

    const response = await connectUser(mail,password)
    // Bon identifiants
    if(!response.message){ 
      navigate('/dashboard')
    }

    // Mauvais identifiants
    if(response.message){
      setErrorsMessage(response)
    }

    setLoading(false)
  }

  /* Keypress for submit */

  const enterPress =  useKeyPress('Enter')
  useEffect(() => {
    
    if(enterPress && !loading){
      getTokenConnexion()
    }
    // eslint-disable-next-line
  }, [enterPress,loading])
  
  
  return (
    <ConnexionLayout {...props}>
          <Box sx={{backgroundColor:theme.palette.primary.main,zIndex:2,width:'800px',maxWidth:'100vw',borderRadius:[0,0,theme.shape.borderRadius]}}>
              <Box sx={{paddingX:[3,3,'100px'],paddingY:'40px',backgroundColor:theme.palette.background.default,borderRadius:[0,0,theme.shape.borderRadius]}}>
                  <Box sx={{textAlign:'center',marginBottom:'20px'}}>
                    <Typography variant="h1" sx={{marginBottom:'10px'}}>
                      Mon espace recrutement
                    </Typography>
                    <Typography>
                    Bienvenue sur votre espace Mousquetaires qui vous permettra de suivre chaque étape de votre parcours de recrutement.
                    </Typography>
                  </Box>
                  <Box sx={{marginY:1}}>
                      <TextFieldCustom  value={mail} isError={errorsMessage && errorsMessage.errors && errorsMessage.errors.email ? true : false}  label="Adresse mail" type='email' onChange={(e)=>{setMail(e.target.value)}}/>
                      {errorsMessage && errorsMessage.errors && errorsMessage.errors.email && <ErrorsField errors={errorsMessage.errors.email}/>}
                  </Box>
                  <Box sx={{marginY:1}}>
                      <TextFieldCustom 
                      value={password}
                      label="Mot de passe" 
                      isError={errorsMessage && errorsMessage.errors && errorsMessage.errors.password ? true : false} 
                      type={showPassword ? "text" : "password"} onChange={(e)=>{setPassword(e.target.value)}} endIcon={<IconButton
                            aria-label="toggle password visibility"
                            onClick={(e)=>{handleShowPassword()}}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>}/>
                          {errorsMessage && errorsMessage.errors && errorsMessage.errors.password && <ErrorsField errors={errorsMessage.errors.password}/>}
                  </Box>
                  
              
                      <Link style={{textDecorationColor:'#616151'}} to="/mot-de-passe-oublie">
                        <Typography sx={{fontStyle:'italic',fontSize:'12px'}} > Mot de passe oublié ?</Typography>
                      </Link>



                    {errorsMessage && 
                    <Box sx={{marginTop:3,display:'flex',justifyContent:'center'}}>
                      <Typography variant="body1" sx={{color:'#FF2822',fontWeight:500,fontSize:'14px'}}>{errorsMessage.message}</Typography>
                    </Box>
                    }
             

                    <Grid container sx={{marginY:1, display:'flex', flexDirection:['column-reverse','column-reverse','inherit']}}  spacing={2}>
                      <Grid item xs={12} md={9} sx={{display:'flex',justifyContent:['center','center','flex-start'],alignItems:'center'}}>
                        <Box sx={{display:'flex',flexDirection:['column','column','row'],gap:1,alignItems:'center',fontSize:'14px'}}>
                        <Typography fontSize='14px' sx={{fontStyle:'italic'}}>
                          C’est votre première connexion ?   
                        </Typography>
                          <Link style={{textDecorationColor:'black'}} color="black" to="/premiere-connexion">
                            <Typography sx={{fontWeight:500,fontSize:'14px',color:'black'}} >Définissez votre mot de passe</Typography>
                          </Link>
                        </Box>
                        
                      </Grid>
                      <Grid item xs={12} md={3} sx={{display:'flex',justifyContent:['center','center','flex-end']}}>
                        <LoadingButton 
                        loading={loading}
                        onClick={()=>{
                          getTokenConnexion()
                        }}
                        variant="contained">
                          Connexion
                        </LoadingButton>
                       
                      </Grid>

                    </Grid>

              </Box>
             

          </Box>
      </ConnexionLayout>
    )
};

export default Connexion;
