export const defaultTheme = {
  palette: {
    type: 'light',
    primary: {
      main: '#FF2822',
      dark: '#C5282A',
      light: '#B2191E',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#880000',
    },
    text: {
      primary: '#000000',
      secondary: '#616161',
    },
    info: {
      main: '#115479',
    },
    warning: {
      main: '#EEC313',
    },
    success: {
      main: '#67B870',
    },
  },
  typography: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: 'Red Hat Display',
    h1: {
      fontSize: 36,
      lineHeight: 1.25,
      fontWeight: 600,
      fontFamily: 'Red Hat Display',
    },
    modalTitle:{
      fontSize: 36,
      lineHeight: 1.25,
      fontWeight: 600,
      fontFamily: 'Red Hat Display',
    },
    h2: {
      fontSize: 30,
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily: 'Red Hat Display',
    },
    h3: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: 1.25,
      fontFamily: 'Red Hat Display',
    },
    h4: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: 1.25,
      fontFamily: 'Red Hat Display',
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 1.25,
      color:'#616151'
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
      color:'#424242'
    },
    small: {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: 1.25,
      color:'#616151'
    },
    subtitle: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.25,
      color:'#424242',
      fontFamily: 'Red Hat Display',
    },
    bodyBlack:{
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
      color:'#0A0A0A',
      fontFamily: 'Red Hat Display',
    },
    debugInfos:{
      fontSize: 12,
      fontWeight: 400,
      color:'black',
      fontFamily: 'Red Hat Display',
    },
    debugInfosKey:{
      fontSize: 12,
      fontWeight: 600,
      color:'black',
      fontFamily: 'Red Hat Display',
    },
    inputLabel:{
      fontSize: 14,
      fontWeight: 500,
      color:'#0A0A0A',
      fontFamily: 'Red Hat Display',

    },
    navLink: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.25,
      color:'#0A0A0A'
    },
    candidate: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '22.5px',
      color:'#FF2822'
    },
    error: {
      color:'#FF2822',
      fontWeight: 600,
    },
    etape:{
      fontSize:['14px','14px','10px'],
      lineHeight:'10px',
      fontFamily: 'Red Hat Display',
      fontWeight:300,
    },
    etapeTitle:{
      fontFamily: 'Red Hat Display',
      fontWeight:700,
      fontSize:['18px','18px','15px'],
      '@media (max-width:1400px)': {
        fontSize: '14px'
      },
      '@media (max-width:1200px)': {
        fontSize: '13px'
      },
      '@media (max-width:1100px)': {
        fontSize: '12px'
      }

    },
    h5: {
      fontFamily: 'Red Hat Display',
    },
    h6: {
      fontFamily: 'Red Hat Display',
    },
    button: {
        fontSize: 16,
        fontFamily: 'Red Hat Display',
        textTransform:'initial',
        elevation:0
      },
    
  },
  shape: {
    borderRadius: 2,
  },
  spacing: 10,
  sidebar:{
    width:360},
  components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow:'none'
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius:'12px'
          },
        },
      },
  },
};