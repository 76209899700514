import React from "react";
import {Box, Item, Span } from "react-html-email";
import MailContainer, { Button } from "./MailContainer";

const InvitationMail = (props) => {

    return (
        <MailContainer>
            <Box align="center" style={{paddingTop:'35px'}}>
                <Item>
                    <Span style={{fontWeight:'600',fontSize:'18px',color:'black'}}>Bonjour [identite]</Span>
                </Item>
            </Box>

            <Box style={{paddingLeft:'10px',paddingRight:'10px'}}>
                <Item>
                    <Box style={{backgroundColor:'white',paddingTop:'50px',paddingBottom:'50px',paddingLeft:'40px',paddingRight:'40px',borderRadius:'12px',marginTop:'35px',marginBottom:'35px',boxShadow:'0px 6px 16px 0px #00000014',textAlign:'center',fontWeight:'400',fontSize:'18px'}}>
                        <Item>
                            <Box align="center" style={{paddingBottom:'20px'}}>
                                <Item>
                                    <Span style={{color:'#FF2822',fontWeight:'600'}}>
                                    Bonjour, Nous vous souhaitons la bienvenue sur votre espace recrutement Mousquetaires !
                                    </Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{paddingBottom:'20px'}}>
                                <Item>
                                    <Span style={{fontWeight:600}}>Ce compte personnalisé </Span> 
                                    <Span>vous permettra de suivre votre</Span>
                                    <Span style={{fontWeight:600}}> parcours de recrutement</Span> 
                                    <Span> et de compléter votre dossier de candidature.</Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{paddingBottom:'20px'}}>
                                <Item>
                                    <Span>Vous trouverez ci-dessous le lien pour vous connecter. </Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{paddingBottom:'20px'}}>
                                <Item>
                                    <Span>Munissez-vous de votre CV actualisé et remplissez sans plus attendre votre dossier administratif !</Span>
                                </Item>
                            </Box>

                            <Box align="center" style={{paddingTop:'20px',paddingBottom:'0px'}}>
                                <Item>
                                    <Button href={'[lien]'}>Se connecter</Button>
                                </Item>
                            </Box>
                        </Item>
                    </Box>
                </Item>
            </Box>
        </MailContainer>
    )
}

export default InvitationMail;

