import React,{useEffect, useState} from 'react';
import './FieldCheckbox.scss';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useUser } from '../../../services/userContext';


const FieldCheckbox = (props) => {
  const {field,onChange} = props
  const [isChecked,setIsChecked] = useState(field.value || 0)
  const {isDebug} = useUser()

  const handleChange = (event) => {
    const isChecked = event.target.checked
    setIsChecked(isChecked)
  }

  useEffect(()=>{
    onChange(field.name,isChecked ? 1 : 0,'boolean')
    // eslint-disable-next-line
  },[isChecked])

  return (
    <FormGroup>
      {isDebug && field.name + ' : ' + field.value}
      <FormControlLabel sx={{pointerEvents: "none"}} control={<BpCheckbox sx={{pointerEvents: "auto"}} name={field.name} checked={Boolean(isChecked)} onChange={handleChange} />} label={<Typography sx={{pointerEvents: "auto"}}>{field.label} {field.isRequired ? '*' : ''}</Typography>} />
    </FormGroup>
  )
};
export default FieldCheckbox;


export const BpCheckbox = (props) => {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon><CheckIcon sx={{width:'15px',height:'15px'}} /></BpCheckedIcon>}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border:"1px solid #C2C2C2",
  backgroundColor: 'white',
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FF2822',
  border: '1px solid #FF2822',
  color:'white',
  display:'flex',
  alignItems:'center',
  justifyContent:'center'
});