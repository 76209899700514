import { Box, Button, Dialog, FormControlLabel, Grid, IconButton, InputLabel, Typography, useMediaQuery, Chip } from '@mui/material';
import React, { useState,useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import './FieldArea.scss';
import CloseIcon from '@mui/icons-material/Close';
import MapFrance from '../../MapFrance/MapFrance';
import { useForm } from '../../../services/formContext';
import { useTheme } from '@emotion/react';
import { getDepartmentsByRegions } from '../../../services/utils';
import { BpCheckbox } from '../FieldCheckbox/FieldCheckbox';
import ErrorsField from '../../ErrorsField/ErrorsField';
import { getValue } from '@testing-library/user-event/dist/utils';
import { useUser } from '../../../services/userContext';



const FieldArea = (props) => {
  
  const {getRegionsSelected} = useForm()

  const {field} = props
  const [open,setOpen] = useState(false)
  const [regionsSelected,setRegionsSelected] = useState(getRegionsSelected(field))

  const {isDebug} = useUser()

  useEffect(()=>{
    setRegionsSelected(getRegionsSelected(field))
    // eslint-disable-next-line
  },[open])

  const handleOpenRegion = () => {
    setOpen(true)
  }


  const borderColor = props.field.errors ? '1px solid #FF2822' : (regionsSelected ? '1px solid #757575' : '1px solid #DBDBDB')

  return (
  <>
  <Box>
    {isDebug && <>
    
    {JSON.stringify(field.value)}
    {JSON.stringify(getRegionsSelected(field))} 
    
    </>}
    <InputLabel>
      <Typography variant="inputLabel">{field.label} {field.isRequired && '*'}</Typography>
    </InputLabel>
    <Box 
    onClick={handleOpenRegion}
    sx={{backgroundColor:'white',height:regionsSelected ? 'inherit': '45px',display:'flex',justifyContent:'space-between',alignItems:'center',padding:1,cursor:'pointer',border:borderColor,borderRadius:'4px'}}>
      <Box>
        {regionsSelected &&  regionsSelected.length > 0 ? <Box sx={{display:'flex',gap:1,flexWrap:'wrap'}}>{regionsSelected.map((region,index)=>(
          region?.label && <Chip key={index} label={region?.label}  />
        ))}

        </Box>
        : field.label}
        
        </Box>
      <Box><AddIcon /></Box>
    </Box>
    <ErrorsField errors={props.field.errors} />
    <AreaDialog field={field} open={open} setOpen={setOpen} regionsSelected={regionsSelected} />
  </Box>
  </>
)};



const AreaDialog = (props) => {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const {field,open,setOpen} = props
  const {nameForm,getMultiplesValues,saveMultiplesValues,reloadField} = useForm()
  const [regionsSelected,setRegionsSelected] = useState(props.regionsSelected || []);


  const updateDepartementWhenRegionIsRemoved = () => {
    const departementsInStorage = getMultiplesValues('departments[%index%]',nameForm,null,getValue('departments[%index%]',nameForm))
    const departementsAvailable = getDepartmentsByRegions(regionsSelected)
    const departementFiltered = departementsAvailable.filter(item => departementsInStorage.includes(item))

    console.log('departementFiltered',departementFiltered)

    saveMultiplesValues('departments[%index%]',departementFiltered,nameForm)
    reloadField('departments[%index%]')
    // TO DO : add specific refresh on specific field
  }


  const handleClickRegion = (idRegion,toBeChecked) => {
      const regionSelected = field.options.find((region)=>region.value === idRegion)
      let newRegions
      if(toBeChecked){
        newRegions = [...regionsSelected,regionSelected]
      }else{
        newRegions = regionsSelected.filter((region)=> region.value !== idRegion)
      }
      setRegionsSelected(newRegions)   
  }

  const isAlreadyChecked = (idRegion) => {
    return regionsSelected?.find((region)=>region.value === idRegion) ? true : false
  } 

  const handleCloseModal = () => {
    setOpen(false)

    let idsRegionsSelected = []
    regionsSelected.forEach(element => {
      idsRegionsSelected.push(element.value)
    });

    saveMultiplesValues(field.name,idsRegionsSelected,nameForm,'number')
    updateDepartementWhenRegionIsRemoved()
  }

  return (
    <Dialog maxWidth="md" fullScreen={fullScreen} fullWidth open={open} onClose={()=>{setOpen(false)}}>
      <Box sx={{padding:7,position:'relative'}}>
          <Box sx={{position:'absolute',right:5,top:5}}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{display:'flex',justifyContent:'center',gap:2,flexDirection:'column',alignItems:'center',textAlign:'center'}}>
            <Typography variant="h2">{field.label}</Typography>
            <Typography variant="body1">{field.help}</Typography>
          </Box>
          <Box sx={{marginY:3}}>
             <Grid container spacing={3}>
                <Grid item xs={12} md={6} >
                  <MapFrance />
                </Grid>
                <Grid item xs={12} md={6} sx={{display:'flex',alignItems:'center'}}>
                    <Box sx={{padding:3,border:'1px solid #E0E0E0',backgroundColor:'#F5F5F5',borderRadius:'6px'}}>
                        <Grid container>
                          {field.options && field.options.map((option,key)=>(
                            <Grid item key={key} xs={6}>
                              <FormControlLabel label={option.label} control={<BpCheckbox checked={isAlreadyChecked(option.value)} onClick={(e)=>{handleClickRegion(option.value,e.target.checked)}}/>}  />
                            </Grid>
                          ))}
                        </Grid>
                    </Box>
                </Grid>
             </Grid>
          </Box>
          <Box sx={{display:'flex',justifyContent:'center',marginTop:4}}>
            <Button onClick={handleCloseModal} variant="contained">Confirmer la sélection</Button>
          </Box>
      </Box>
    </Dialog>
  )


}


export default FieldArea;
