import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FieldSet from '../../components/Fields/FieldSet/FieldSet';
import {useForm} from '../../services/formContext';
import Field from '../Fields/Field/Field';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import AlertInfoCustom from '../AlertInfoCustom/AlertInfoCustom';
import LoadingFullPage from '../LoadingFullPage/LoadingFullPage';
import { getCurrentFormGroup, getModalInfoParcoursCoupleIsViewed} from '../../services/localStorage';
import ModalFormulaireIsLocked from '../ModalFormulaireIsLocked/ModalFormulaireIsLocked';
import ModalSendingFormulaire from '../ModalSendingFormulaire/ModalSendingFormulaire';
import navItems from '../NavItems/NavItems';
import ModalInfoParcoursCouple from '../ModalInfoParcoursCouple/ModalInfoParcoursCouple';
import { useUser } from '../../services/userContext';
import { useNotifications } from '../../services/notificationsContext';
import ModalDossierForbiden from '../ModalDossierForbiden/ModalDossierForbiden';
import ModalSendingDossier from '../ModalSendingDossier/ModalSendingDossier';
import getSizeField from '../../services/getSizeField';
import ModalMaxWeightOfSize from '../ModalMaxWeightOfSize/ModalMaxWeightOfSize';
import DebugComptable from '../DebugComptable/DebugComptable';

const FormulaireLayout = (props) => { 
  
  
  const {getTypeParcours,isDebug} = useUser()
  const {idFormulaire} = props
  const [isLoaded,setIsLoaded] = useState(false)
  const {form,nameForm,initForm,candidateTarget,submitFormulaire,responseFormulaire,hasFieldsReadOnly,formIsLoading,fieldCountable,fieldsLoading} = useForm()
  const {idTarget} = useParams()
  const navigate = useNavigate()
  const [sendingFormulaire,setSendingFormulaire] = useState(false)
  const [modalInfoParcoursCouple,setModalInfoParcoursCouple] = useState(false);
  const {pushNotifications} = useNotifications()
  const [modalSendingDossier,setModalSendingDossier] = useState(false);

  const [modalMaxWeightOfSize,setModalMaxWeightOfSize] = useState(false)

  const getFormLayout = async () => {
    setIsLoaded(false)
    if(idTarget){
      await initForm(idFormulaire,idTarget)
    }else{
      await initForm(idFormulaire)
    }
    setIsLoaded(true)
  }

  // Hook au chargement de la page
  useEffect(()=>{
    if(!isLoaded){
      getFormLayout()
    }
    // eslint-disable-next-line
  },[isLoaded])

  // Hook au changement d'url
  useEffect(()=>{
    if(isLoaded){
      getFormLayout()
    }
    // eslint-disable-next-line
  },[idFormulaire,idTarget])

  useEffect(()=>{
    if(form?.has_pending_exports){
      setModalSendingDossier(true)
    }
  },[form])

  useEffect(()=>{
    if(getTypeParcours() === 'Couple'){
      const modalIsViewed = getModalInfoParcoursCoupleIsViewed(idTarget || 'commun')
      console.log('modalIsViewed',idTarget,modalIsViewed)
      if(!modalIsViewed){
        console.log('display popup')
        setModalInfoParcoursCouple(true)
      }
    }
    // eslint-disable-next-line
  },[idTarget])

  const handleSubmitFormulaire = async () => {

    setSendingFormulaire(true)

    const sizePrefetch =  await submitFormulaire(nameForm,true)
    console.log('Taille totale de l\'envoi des fichiers',sizePrefetch);

    if(sizePrefetch > process.env.REACT_APP_PHP_MAX_SIZE){
      setModalMaxWeightOfSize(true)
    }else{
      const response = await submitFormulaire(nameForm)
      // Redirection  vers le dossier si il n'y a pas d'erreur
      if(!response.errors){
        const slug = navItems.find(item => item.form_group_id === getCurrentFormGroup()) ? navItems.find(item => item.form_group_id === getCurrentFormGroup()).slug : false
        
        if(form.form_group_id === 2){
          setModalSendingDossier(true)
        }else{
          navigate(slug ? '/'+slug : '/dashboard')
        }
        pushNotifications(response.message,'success')
      }
    }
    setSendingFormulaire(false)

  }





  
  return(
    <>
    
    {formIsLoading && <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'calc(100vh - 300px)'}}><LoadingFullPage /></Box>}

    {!formIsLoading && form.error && <ModalFormulaireIsLocked open={form.error} form={form} />}

    {form?.are_files_validated && <ModalDossierForbiden open/>}
    {modalSendingDossier && <ModalSendingDossier open={modalSendingDossier} />}
    
    {sendingFormulaire && <ModalSendingFormulaire open={true} />}

    {!formIsLoading && form.name === 'Patrimoine' && modalInfoParcoursCouple && <ModalInfoParcoursCouple form={form} open={modalInfoParcoursCouple} setOpen={setModalInfoParcoursCouple} candidateTarget={candidateTarget} /> }

    {modalMaxWeightOfSize && <ModalMaxWeightOfSize open form={form} onClose={()=>{setModalMaxWeightOfSize(false)}}/>}


    {form && 
    <>
    <Box>
      <Box sx={{display:'flex',flexDirection:['column','row','row'],gap:2,marginBottom:1,alignItems:['flex-start','flex-start','flex-end']}}>
        <Typography variant="h2">{form.form_group_name}</Typography>
      </Box>
      {form.form_group_description && <Typography sx={{marginY:2}}>{form.form_group_description}</Typography>}
    </Box>
    {hasFieldsReadOnly() && 
      <Box sx={{marginBottom:4}}>
        <AlertInfoCustom>
          <Typography sx={{fontWeight:500,color:'#115479'}}>Certains champs ne sont pas modifiables. Les données sont celles fournies lors de votre inscription. Veuillez contacter Les Mousquetaires en cas de modification.</Typography>
        </AlertInfoCustom>
      </Box>
    }

    {isDebug && 
    <Box sx={{marginY:5}}>
      <Typography>Fields comptables :</Typography>
      <DebugComptable fields={fieldCountable} />
      
    </Box>}


    <Box>
      <Box sx={{display:'flex',flexDirection:['column','column','row'],gap:3,alignItems:['start','start','end'],marginBottom:2}}>
        <Typography variant="h3">{form.name}</Typography>
        {candidateTarget && getTypeParcours() === 'Couple' && <Typography variant="candidate">{candidateTarget.title}</Typography>}
      </Box>

      <Grid container spacing={1}>
        {form.fields && form.fields.map((field,key)=>{
          const sizeField = getSizeField(field,nameForm,'formulaireLayout')
          return (
          <React.Fragment  key={key} >
            {field.fields && <Grid key={key} item xs={12}><FieldSet field={field} /></Grid> }
            {!field.fields && <Grid key={key} item xs={12} md={sizeField}><Field field={field} parent={'formulaireLayout'} /></Grid> }
          </React.Fragment>
        )})}
      </Grid>
      <Box sx={{marginTop:4,textAlign:'center'}}>
        {responseFormulaire &&
          <Box sx={{marginBottom:2}}>
              {isDebug && <Typography variant="body1" sx={{marginBottom:2}}>{JSON.stringify(responseFormulaire)}</Typography>}
              <Typography variant="body1">{responseFormulaire.message}</Typography>
          </Box>
        }
        <Box sx={{display:'flex',gap:1,justifyContent:'center'}}>
          <LoadingButton loading={sendingFormulaire || fieldsLoading} onClick={handleSubmitFormulaire} variant="contained">Valider ma saisie</LoadingButton>
        </Box>
      </Box>
    </Box>
    </>
    }
    </>)
};

export default FormulaireLayout;
